import React, { useEffect } from 'react'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Red_Hat_Display } from 'next/font/google'
import Head from 'next/head'
import Script from 'next/script'

import { Toaster } from 'sonner'
import { SWRConfig } from 'swr'

import useInactivityScreen from '@/hooks/useInactivityScreen'
import initApm from '@/common/apm.monitor'
import setupAxios from '@/common/axios.interceptor'

import '@/styles/globals.css'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import MaintenanceMode from './_maintenance'
import MainLayout from '@/components/layouts/main'

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const RedHatDisplay = Red_Hat_Display({
  subsets: ['latin'],
  display: 'swap',
})

export default function App({
  Component,
  pageProps: { ...pageProps },
}: AppPropsWithLayout) {
  const { data, mutate } = useInactivityScreen()
  initApm('payments-topup-platform')
  setupAxios()

  useEffect(() => {
    mutate()
  }, [])

  const getLayout =
    Component.getLayout ||
    ((page) => (
      <>{data ? <MaintenanceMode /> : <MainLayout>{page}</MainLayout>}</>
    ))

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width" />
        <meta
          name="description"
          content="Recarga tu celular, paga tus servicios y mucho más."
        />
        <meta name="keywords" content="Keywords" />
        <title>Luegopago Pagos y Recargas</title>
        <link rel="manifest" href="/manifest.json" />
        <link
          href="/icons/icon-16x16.png"
          rel="icon"
          type="image/png"
          sizes="16x16"
        />
        <link
          href="/icons/icon-32x32.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
        <link rel="apple-touch-icon" href="/icons/icon-72x72.png"></link>
        <meta name="theme-color" content="#6064FF" />
      </Head>
      <Script
        async
        defer
        src="https://unpkg.com/@elastic/apm-rum@5.12.0/dist/bundles/elastic-apm-rum.umd.min.js"
      />
      {/* hotjar */}
      <Script
        id="hotjar"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:2384061,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `,
        }}
      />
      <Script async defer id="user-ip">
        {`
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        const clientIp = data.ip;
        window.localStorage.setItem('ip', clientIp);
      })
      .catch(error => console.error('Error fetching IP:', error));
  `}
      </Script>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
          <iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TAG}"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>
          `,
        }}
      ></noscript>
      <SWRConfig
        value={{
          revalidateOnFocus: true,
          errorRetryCount: 1,
        }}
      >
        <main className={RedHatDisplay.className}>
          {getLayout(
            <div className="no-scrollbar flex h-full w-full flex-col items-center gap-3 overflow-y-auto scroll-smooth rounded-t-xl bg-white px-4 py-2 md:rounded-t-3xl md:p-10 lg:px-60 xl:px-80">
              <Toaster richColors={true} position="top-center" />
              <Component {...pageProps} />
            </div>
          )}
        </main>
      </SWRConfig>
    </>
  )
}
